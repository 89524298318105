import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode"
import { useNavigate } from "react-router-dom";
import { UserContext} from '../context/UserContext'
import { IMessage } from '../Interfaces/IMessage'

export const UserContextProvider = ({children}: {children: React.ReactNode}) => {
    
    const [csv_file_object,setcsv_file_object] = useState([])
    const [authTokens,setauthTokens] = useState(()=>localStorage.getItem('authTokens')? JSON.parse(localStorage.getItem('authTokens')|| '{}') : null )
    const [user,setuser] = useState(()=>localStorage.getItem('authTokens')? jwt_decode(localStorage.getItem('authTokens')|| '{}') : null )
    const navigate = useNavigate()
    const [loading,setloading] = useState(true)
    const [ActiveSiteId, setActiveSiteId] = useState<number>(0)
    const [ActiveOrgId, setActiveOrgId] = useState<number>(0)
    const loginUser = async (event:any) =>{
        event.preventDefault()
        const response = await fetch('/api/token/',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'username':event.target.username.value, 'password':event.target.password.value})
        })

        const data = await response.json()
        if(response.status === 200){
            setauthTokens(data)
            setuser(jwt_decode(data.access))
            localStorage.setItem('authTokens',JSON.stringify(data))
            navigate('/select_site')
        }else{
            alert('Something went wrong!')
        }
    }

    const logoutUser = () => {
        setauthTokens(null)
        setuser(null)
        localStorage.removeItem('authTokens')
        navigate('/')
    }

    const updateToken = async () =>{
        const response = await fetch('/api/token/refresh/',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'refresh':authTokens.refresh})
        })
        const data = await response.json()
        if(response.status === 200){
            setauthTokens(data)
            setuser(jwt_decode(data.access))
            localStorage.setItem('authTokens',JSON.stringify(data))
            
        }else{
            logoutUser()
        }
    }

    const getSiteName = async () =>{
        const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
        const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
        const response =  await fetch('api/'+ActiveOrgId+'/'+ActiveSiteId,
        {  
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                
            }
        })
        const data = await response.json()
        return data.site_name
    }
    //////////////////////////////////////////////////////////////////////////////
    const [taskId, setTaskId] = useState(null);
    const [conversation, setConversation] = useState<IMessage[]>([]);
    //////////////////////////////////////////////////////////////////////////////
    const getDaterange = async () =>{
        const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
        const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
        const response =  await fetch('api/'+ActiveOrgId+'/'+ActiveSiteId+'/chatbot/daterange')
        try{
            if (!response.ok) {
                throw new Error('Network response was not ok');
                
              }
            const htmlContent = await response.json();
            console.log(htmlContent.task_id); 
            setTaskId(htmlContent.task_id);
            // if (response.ok) {
                
            //     const data = await response.json();
            //     console.log(data)
            //     const start_date = data.first_timestamp
            //     const last_date = data.last_timestamp
            //     return [start_date, last_date]
            //     // console.log(response)
            //     //return response
            // }
            // else {
            //     const data = await response.json();
            //     if (data && data.error) {
            //         return data.error;
            //     }
            //}
           }
        
        catch(error)
        {
            console.error('There was a problem with the request:', error);
        }   
       
    }



    const changeSite = (newSiteId:number) => {
        if(ActiveSiteId !== newSiteId)
        {   setActiveSiteId(newSiteId)
            sessionStorage.setItem('ActiveSiteId',newSiteId.toString());
            
        }
    }
    const changeOrgId = (newOrgId:number) => {
        if(ActiveOrgId !== newOrgId)
        {
            setActiveOrgId(newOrgId)
            sessionStorage.setItem('ActiveOrgId',newOrgId.toString());
            
        }
    }
    
    const Chatbot = async () => {
        const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
        const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
        const url = 'api/'+ActiveOrgId+'/'+ActiveSiteId+'/chatbot/get_files'
        try
          {   
          const response = await fetch(url)
          if (response.ok)
            {
            console.log("Data updated")
            }
          else
            {
            console.error("File Not Found:")
            }   
            }
        catch(error)
          {
          console.error('Error fetching data:', error);
          }
    }

    useEffect(()=>{
        const minutes = 1000 * 60 * 15
        const interval = setInterval(()=>{
            if(authTokens){
                updateToken()
            }
        },minutes)
        return ()=> clearInterval(interval)
    }, [authTokens,loading])

    //////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (!taskId) return;
        
     
        const interval = setInterval(async () => {
              const response = await fetch(`api/task-status/${taskId}/`);
              console.log(response)
              const data = await response.json();
              console.log(data);
              console.log(taskId)
              // setStatus(data.status);
              if (data.status === "SUCCESS") {
                 console.log("got the result");
                
                const parsedResult = JSON.parse(data.result.replace(/'/g, '"'));
                try{
                    clearInterval(interval);
                    sessionStorage.setItem('daterange', JSON.stringify(parsedResult));
                    
                } catch (error) {
                    
                    console.error('Error fetching date range:', error);
                    return null; // Handle error case
                }
                
              }
              if (data.status === "FAILURE")
              {
                console.log("FAIL");
                clearInterval(interval);
              }
     
        }, 1000);
     
        return () => clearInterval(interval);
      }, [taskId]);

    useEffect(() => {
        window.localStorage.setItem('ActiveSiteId', JSON.stringify(ActiveSiteId));
      }, [ActiveSiteId])
    //////////////////////////////////////////////////////////////////
    
    return(
        <UserContext.Provider value={{
            authTokens,
            user,
            logoutUser,
            loginUser,
            changeSite,
            ActiveSiteId,
            setActiveSiteId,
            changeOrgId,
            ActiveOrgId,
            setActiveOrgId,
            Chatbot,
            getSiteName,
            getDaterange,
            csv_file_object,
            }}>
            {children}
        </UserContext.Provider>
    )
}