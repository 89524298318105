import './App.css';
import './styles/main.css';


import {
    Routes,
    Route,
} from "react-router-dom"
import { UserContextProvider } from './components/UserContextProvider';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import Org_Site_Listpage from './components/Org_Site_Listpage';
import Forgot_Password from './components/Forgot_Password';
import  {Password_confirmation,PasswordResetComplete} from './components/Password_confirmation';
import Sidebar_Settings_page from './components/Sidebar_Settings_page';
import Sidebar_Files_page from './components/Sidebar_Files_page';
import Sidebar_chat from './components/Sidebar_chat';

function App() {
    return (
        <UserContextProvider>
            
            <Routes>
                <Route path="/" element={<Login/>} />
                <Route path="/forgot_password" element={<Forgot_Password/>}/>
                <Route path="/reset/:uid/:token/" element={<Password_confirmation/>}/>
                <Route path="/reset_password_complete/" element={<PasswordResetComplete/>}/>
                <Route  element={<PrivateRoute/>}>
                    <Route path='/select_site' element={<Org_Site_Listpage/>}/>
                    <Route path='/chat' element={<Sidebar_chat/>}/>
                    <Route path='/scadaconnection' element={<Sidebar_Settings_page/>}/>
                    <Route path='/files' element={<Sidebar_Files_page/>}/>
                </Route>
            </Routes>
        </UserContextProvider>
        );
}

export default App;