import React from 'react'
import Sidebar_menu from './Sidebar_menu'
import Settings from './Settings'
import { useState } from 'react';

const Sidebar_Settings_page = () => {
  const [allChats, setAllChats] = useState<{ id: number, messages: { sender: string, content: string ,timestamp: string, chat_thread: string }[] }[]>([]);
  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const handleChatSelection = (chatId: number) => {
      setSelectedChatId(chatId);
    };
  const handleStartNewChat = () => {
      const newChatId = allChats.length + 1;
      setAllChats([...allChats, { id: newChatId, messages: [] }]);
      setSelectedChatId(newChatId);
    };
  return (
    <div className='main_box'>
      <div className='leftside'>
      <Sidebar_menu setAllChats={setAllChats} onChatSelect={handleChatSelection} />
      </div>
      <div className='rightside'>
      <Settings />
      </div>
    </div>
  )
}

export default Sidebar_Settings_page
