import React, { useState, useEffect, useContext,useRef } from 'react';
import Cookies from 'js-cookie';
import { UserContext } from '../context/UserContext'
import { IMessage } from '../Interfaces/IMessage'
import Chat_section from './Chat_section';
import Left_side from './Sidebar_menu';

const Sidebar_chat = () => {
    const {getDaterange,getSiteName} = useContext(UserContext)
    const [conversation, setConversation] = useState<IMessage[]>([]);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [allChats, setAllChats] = useState<{ id: number, messages: { sender: string, content: string,timestamp: string, chat_thread: string  }[] }[]>([]);
    const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
    const handleChatSelection = (chatId: number) => {
      setSelectedChatId(chatId);
      
    };
    // const handleStartNewChat = () => {
    //   const newChatId = allChats.length + 1;
    //   setAllChats([...allChats, { id: newChatId, messages: [] }]);
    //   setSelectedChatId(newChatId);
      
    // };

    useEffect(() => {
    
    if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    }, [conversation]);

   
  
      
    useEffect(() => {
      (async () => {
      if (conversation)
        {
        const sitename = await getSiteName()
        const run_daterange = await getDaterange()
        const daterange = JSON.parse(sessionStorage.getItem('daterange') || '[]');
        const last_timestamp = daterange.last_timestamp
        const first_timestamp = daterange.first_timestamp
        if(daterange=='Site does not exist in database')
  
        {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, There is no data file available for this site. Please upload the data file' )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
      
        else if(daterange=='This site is not communicating currently')

          {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, This site is not communicating currently' )};
          setConversation((prevConversation) => [...prevConversation, newBotMessage]);}

        
        else if(daterange=='No data available for this site or site is not communicating currently')
    
          {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, No data available for this site or site is not communicating currently' )};
          setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
        
          else if (last_timestamp && first_timestamp)
          {
          const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, Data is available for ' +sitename+ ' from ' +first_timestamp+ ' to ' +last_timestamp )};
          setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
        }
      })();
    }, []);


  return (
    <div className='main_box'>
      <div className='leftside'>
      <Left_side setAllChats={setAllChats} onChatSelect={handleChatSelection} />
      </div>
      <div className='rightside'>
      <Chat_section allChats={allChats} selectedChatId={selectedChatId}  />
      </div>
    </div>


  )
}

export default Sidebar_chat
