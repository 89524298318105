import React, { useState } from 'react'
import '../styles/__LeftSide.css'
import { UserContext } from '../context/UserContext';
import { useContext,useEffect} from 'react';
import { IChatThread } from '../Interfaces/IMessage';
import { TiMessageTyping } from "react-icons/ti";
import { useNavigate } from 'react-router-dom'


interface LeftSideProps {
  setAllChats: React.Dispatch<React.SetStateAction<{ id: number, messages: { sender: string, content: string,timestamp: string, chat_thread: string  }[] }[]>>;
  onChatSelect: (chatId: number) => void;
}

const Sidebar_menu:React.FC<LeftSideProps> = ({setAllChats, onChatSelect}) => {
    const {user,logoutUser} = useContext(UserContext)
    const [Chats, setChats] = useState<IChatThread[]>([]);
    const navigate = useNavigate()


    const startNewChat = async () => {
      navigate('/chat')
      const ActiveSiteId = sessionStorage.getItem('ActiveSiteId');
      const ActiveOrgId = sessionStorage.getItem('ActiveOrgId');
      const response = await fetch('api/start-new-chat/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          org_id: ActiveOrgId,
          site_id: ActiveSiteId,
          username: user.username
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const chatId = data.chat_id;
        sessionStorage.setItem('chatId',chatId.toString());
        await fetchChats();
        
      };


    const [error, setError] = useState<string | null>(null);
    const fetchChats = async () => {
      try {
        const ActiveSiteId = sessionStorage.getItem('ActiveSiteId');
        if (!ActiveSiteId) {
          setError('ActiveSiteId not found');
          return;
        }
        
        const url = `api/${ActiveSiteId}/chatrecords`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setChats(data);
        setAllChats(data)
      } catch (error) {
        setError('Error fetching chat history');
      } 
    };

    const summarizeMessages = (messages: any[], maxLength = 100) => {
      // Join messages content into a single string
      const summary = messages.map(message => message.content).join(' ');
    
      // Truncate summary if it's longer than maxLength
      return summary.length > maxLength ? `${summary.substring(0, maxLength)}...` : summary;
    };


    const handlechat = (chatid:any) =>{
      
      onChatSelect(chatid);
    }

  useEffect(() => {
       fetchChats();
      }, []);
    
      
    
      if (error) {
        return <div>{error}</div>;
      }


  return (
    <div className='leftSection'>
        <div className="newChat">
      
            <div  className="newChatContainer">
                <img src={require('../assets/EAI_Logo_5.png')} alt="logo" className='logo'></img>
                <p className='title'>Electra AI</p>
            </div>
            <img src={require('../assets/comment.png')} alt="new chat" className="newChatIcon" onClick={startNewChat} />
        </div>


        <div className="allChats">
        
      
        <div>
        {Chats.map(thread => (
        thread.messages.length > 0 && (
          <div key={thread.id} className='chat-item' onClick={() => handlechat(thread.id)}>
            <div className='icon-container'>
              <TiMessageTyping size="1.5em" style={{ cursor: 'pointer' }} />
            </div>
            <div className='text-container'>
              <button className='summary_button'>{summarizeMessages(thread.messages, 100)}</button>
            </div>
          </div>
        )
      ))}
    </div>
        </div>

        <div className="contentContainer">
        <ul className="ulist">
          <li className="lilist">
          <img src={require('../assets/chat.png')} alt="logo" className='icon_style'></img>
          <a className="link" href='/chat'>Chat</a>
          </li>
          <li className="lilist">
          <img src={require('../assets/icons8-settings-48.png')} alt="logo" className='icon_style'></img><a className="link" href='/scadaconnection'>Settings</a>
          </li>
          <li className="lilist">
          <img src={require('../assets/file.png')} alt="logo" className='icon_style'></img><a className="link" href='/files'>Files</a>
          </li>
          
          
        </ul>
      </div>

      
        <div className="newChat" onClick={logoutUser}>
            <div>
                <img src={require('../assets/user.png')} alt="profile" className='profile'></img>
                <div className="profileContents">
                <p className="name">{user.username}</p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Sidebar_menu
