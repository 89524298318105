import React, { useState , useEffect} from 'react'
import Cookies from 'js-cookie';

const Forgot_password = () => { 
  const [email,setEmail] = useState('') 
  const [message, setMessage] = useState('');
  const csrfToken = Cookies.get('csrftoken');


  const handleEmailChange = (event:any) => {
    setEmail(event.target.value);
  };



  const handleSubmit = async (event:any) => {
    event.preventDefault();

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded',);
    headers.append('X-CSRFToken', csrfToken || '');

    const response = await fetch('api/reset_password/',{
      method:'POST',
      headers: headers,
      body: `email=${email}`,
    })
    .then((response) => {
        if (response.ok) {
          
          setMessage('Password reset email sent successfully.');
          
        } else {
          setMessage('Password reset request failed.');
        }
      })
      .catch((error) => {
        setMessage('An error occurred while sending the request.');
        console.error('An error occurred:', error);
      })
  }
  

  return (
    <form >
      <div className='form-group'>
        <label>Email</label>
        <input type='email' 
        placeholder="Enter your email"
        value={email}
        onChange={handleEmailChange}/>
      </div>
      <button onClick={handleSubmit}>Submit</button>
      <p>{message}</p>
    </form>
    
  )
}

export default Forgot_password
