import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const Password_confirmation = () => {
    const { uidb64, token } = useParams(); 
    const csrfToken = Cookies.get('csrftoken');
    const [confirmationMessage, setConfirmationMessage] = useState('');
  
    useEffect(() => {
      const confirmReset = async () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded',);
        headers.append('X-CSRFToken', csrfToken || '');
        try {
           
          const response = await fetch(`api/reset/${uidb64}/${token}/`, {
            method: 'POST',
            headers: headers,
          });
  
          if (response.ok) {
            
            setConfirmationMessage('Password reset confirmed.');
          } else {

            setConfirmationMessage('Password reset confirmation failed.');
          }
        } catch (error) {
          console.error('Error confirming password reset:', error);
          setConfirmationMessage('An error occurred while confirming the reset.');
        }
      };
  
      confirmReset(); 
    }, [uidb64, token]);
  
    return (
      <div>
        <h2>Password Reset Confirmation</h2>
        <p>{confirmationMessage}</p>
      </div>
    );
};

const PasswordResetComplete = () => {
    return (
      <div>
        <h2>Password Reset Complete</h2>
        <p>Your password has been successfully reset.</p>
        {/* Additional content or redirection logic can be added here */}
      </div>
    );
  };
  
export { Password_confirmation, PasswordResetComplete };
