import { createContext } from "react"
import {IUserContext} from "../Interfaces/IUserContext"


export const UserContext = createContext<IUserContext>({
    user: undefined,
    authTokens: undefined,
    logoutUser: function (): void {
        throw new Error("Function not implemented.")
    },
    loginUser: function (event: any): void {
        throw new Error("Function not implemented.")
    },
    ActiveSiteId: 0,
    setActiveSiteId: undefined,
    
    ActiveOrgId: 0,
    setActiveOrgId: undefined,
    Chatbot: function (): string {
        throw new Error("Chatbot not implemented.")
    },
    getSiteName: function (): string{
        throw new Error("Getting error in fetching site name")
    },
    getDaterange: function (): string{
        throw new Error("Getting error in fetching the data")
    },

    csv_file_object:[],
})

 