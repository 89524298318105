import React, {useContext} from 'react'
import { useNavigate } from "react-router-dom";
import { UserContext } from '../context/UserContext';


const LoginPage = () => {
  const {loginUser} = useContext(UserContext)

  const navigate = useNavigate() 

  const ForgotPasswordPage = () => {
    navigate('/forgot_password')
  }
  return (
    <div className="login-container">
    <div className="login-centered">
      <h2 className='login'>LOGIN</h2>
      <form onSubmit={loginUser}>
        <div className="form-group">
        <label htmlFor="username">Username:</label>
        <input type='text' name='username' placeholder='Enter Username' autoComplete='current-username'/>
        </div>
        <div className="form-group">
        <label htmlFor="password">Password:</label>
        <input type='password' name='password' placeholder='Enter Password' autoComplete="current-password"/>
        </div>
        <div className='Submit-button-div'>
        <button type='submit' className='Submit-button'>Login</button>
       
        </div>
      </form>
    </div>
    </div>
  )
}

export default LoginPage
