import React, { useState,useContext,useRef,useEffect } from 'react';
import '../styles/__Chatsection.css'
import Cookies from 'js-cookie';
import { IMessage } from '../Interfaces/IMessage'
import { UserContext } from '../context/UserContext'

interface ChatSectionProps {
  allChats: { id: number, messages: { sender: string, content: string, timestamp: string, chat_thread: string }[] }[];
  selectedChatId: number | null;
  
}
interface IResponse {
  [key: string]: { date: string; data: IData }[];
}
interface IData {
  [key: string]: number;
}
const Chat_section:React.FC<ChatSectionProps> = ({allChats, selectedChatId }) => {
  
   const [userMessage, setUserMessage] = useState('');
   const [loading, setLoading] = useState<boolean>(false);
   const csrfToken = Cookies.get('csrftoken');
   const [conversation, setConversation] = useState<IMessage[]>([]);
   const {getSiteName,getDaterange, user} = useContext(UserContext)
   const messagesEndRef = useRef<HTMLDivElement>(null);
   const mapSenderToRole = (sender: string) => {
    if (sender == 'assistant')
        return 'assistant';
    else
        return 'user';
    
  };
  

   const handleKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleUserMessage(userMessage);
      
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const sampleQuestions = [
    "1. Tell me the performance of all inverters?",
    "2. How many inverters we have on site?",
    "3. What is the power data of all inverters on 3rd June?",
    "4. What measures need to take if grid voltage is too low?",
  ];



   const handleUserMessage = async (userMessage: any) => {
    if (loading) {
      return
    }
    const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
    const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
    const chatId = sessionStorage.getItem('chatId')
    if(chatId==null)
    { 
      const response = await fetch('api/start-new-chat/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          org_id: ActiveOrgId,
          site_id: ActiveSiteId,
          username: user.username
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const chatiddata = await response.json();
        const chatId = chatiddata.chat_id;
        sessionStorage.setItem('chatId',chatId.toString());
    }
    setLoading(true); 
    const headers = new Headers();
          headers.append('Content-Type', 'application/json',);
          headers.append('X-CSRFToken', csrfToken || '');
  
      const newUserMessage: IMessage = { sender: 'user', content: userMessage};
      setConversation((prevConversation) => [...prevConversation, newUserMessage]);
      console.log(conversation)
      setUserMessage('');
     try {
        const user_name = user.username
        const url = 'api/'+ActiveOrgId+'/'+ActiveSiteId+'/'+'chatpage'
        console.log(url)
        const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({userMessage,conversation,user_name,chatId}),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
          
        }
        const htmlContent = await response.json();
        console.log(htmlContent.task_id); 
        setTaskId(htmlContent.task_id);
        
        } catch (error) {
        console.error('Error:', error);
      }finally {
        setLoading(false);
      }
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  // }
  }, [conversation]);






    useEffect(() => {
    (async () => {

    if (conversation)
      {
      const sitename = await getSiteName()
      const run_daterange = await getDaterange()
      const daterange = JSON.parse(sessionStorage.getItem('daterange') || '[]');
      const last_timestamp = daterange.last_timestamp
      const first_timestamp = daterange.first_timestamp
      
      if(daterange=='Site does not exist in database')
  
        {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, There is no data file available for this site. Please upload the data file' )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
      
      else if(daterange=='This site is not communicating currently')

        {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, This site is not communicating currently' )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}

      
      else if(daterange=='No data available for this site or site is not communicating currently')
  
        {const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, No data available for this site or site is not communicating currently' )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
        
      else if (last_timestamp && first_timestamp)
        {
        const newBotMessage: IMessage = { sender: 'assistant', content: JSON.stringify('Hi, Data is available for ' +sitename+ ' from ' +first_timestamp+ ' to ' +last_timestamp )};
        setConversation((prevConversation) => [...prevConversation, newBotMessage]);}
      }
    })();
  }, []);
  
  
  const [taskId, setTaskId] = useState(null);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState('');

  
 useEffect(() => {
   if (!taskId) return;
   

   const interval = setInterval(async () => {
         const response = await fetch(`api/task-status/${taskId}/`);
         console.log(response)
         const data = await response.json();
         console.log(data);
         console.log(taskId)
         // setStatus(data.status);
         if (data.status === "SUCCESS") {
            console.log("got the result");
             // setResult(data.result);
             const newBotMessage: IMessage = { sender: 'assistant', content: data.result };
            setConversation((prevConversation) => [...prevConversation, newBotMessage])
             clearInterval(interval);
         }
         if (data.status === "FAILURE")
         {
           console.log("FAIL");
           clearInterval(interval);
         }

   }, 1000);

   return () => clearInterval(interval);
 }, [taskId]);

 useEffect(() => {
  if (selectedChatId !== null) {
    console.log(allChats)
    const selectedChat = allChats.find(chat => chat.id === selectedChatId);
    if (selectedChat) {
      setConversation(selectedChat.messages);
    }
  }
  }, [selectedChatId, allChats]);

//   const renderDataAsTable = (data: IData) => {
//     const headers = Object.keys(data)
//         .map(key => `<th>${key}</th>`)
//         .join('');
//     const values = Object.values(data)
//         .map(value => `<td>${value}</td>`)
//         .join('');
    
//     return `
//         <table class="data-table">
//             <thead>
//                 <tc>${headers}</tc>
//             </thead>
//             <tbody>
//                 <tr>${values}</tr>
//             </tbody>
//         </table>
//     `;
// };
// const isJSON = (str: string) => {
//   try {
//       const parsed = JSON.parse(str);
//       return typeof parsed === 'object' && parsed !== null;
//   } catch (e) {
//       return false;
//   }
// };
// const renderResponse = (content: any) => {
//   if (isJSON(content)) {
//     const parsedContent = JSON.parse(content);

//     return (
//       <div>
//       {Object.keys(parsedContent).map((key, index) => (
//           <div key={index}>
//               <h3>{key}</h3>
//               {Array.isArray(parsedContent[key]) ? (
//                   <table style={{ borderCollapse: 'collapse', width: '100%' }}>
//                       <thead>
//                           <tr>
//                               <th style={{ border: '1px solid black', padding: '8px' }}>Date</th>
//                               {parsedContent[key].length > 0 &&
//                                   Object.keys(parsedContent[key][0].data).map((dataKey, dataIndex) => (
//                                       <th key={dataIndex} style={{ border: '1px solid black', padding: '8px' }}>{dataKey}</th>
//                                   ))}
//                           </tr>
//                       </thead>
//                       <tbody>
//                           {parsedContent[key].map((item: any, idx: number) => (
//                               <tr key={idx}>
//                                   <td style={{ border: '1px solid black', padding: '8px' }}>{item.date}</td>
//                                   {Object.keys(item.data).map((dataKey, dataIndex) => (
//                                       <td key={dataIndex} style={{ border: '1px solid black', padding: '8px' }}>{item.data[dataKey]}</td>
//                                   ))}
//                               </tr>
//                           ))}
//                       </tbody>
//                   </table>
//               ) : (
//                   <div>{JSON.stringify(parsedContent[key])}</div>
//               )}
//           </div>
//       ))}
//   </div>
// );
// } else {
// // Handle plain text content
// return <div>{content}</div>;
// }
// };

const ResponseComponent = ({ response }: { response: string }) => {
  
  const cleanString = (str: string) => {
    // Remove multiple \n characters (2 or more) and replace them with a single space
    return str.replace(/\n{2,}/g, '\n').trim();
  };
  const cleanedResponse = cleanString(response);
  return (
    <div
      dangerouslySetInnerHTML={{ __html: cleanedResponse }}
    />
  );
};

  return (
    <div className='Chat_section'>
    
    <div className='chat-container'>
        <div className='msgbox' >
        <div className='chat-messages'>
            {conversation.map((message, index) => (
              <div key={index} className={`message ${mapSenderToRole(message.sender)}`}>
                {message.sender === 'assistant' ? (
                  <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
                ) : (
                  <div>{message.content}</div>
                )}
              </div>
            ))}
            {/* {conversation.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                    <ResponseComponent response={message.content} />
                </div>
            ))} */}
           <div ref={messagesEndRef}></div>
          </div>
        </div>
        </div>
    <div className="bottomsection">
        <div className="messagebar">
            <input type='text' placeholder='Type a message...'
            onChange={(e)=> setUserMessage(e.target.value)}
            value={userMessage} disabled={loading} onKeyDown={handleKeyPress}/>
            <svg onClick={handleUserMessage} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
            </svg>


        </div>
    </div>
    <div
      className="floating-chat-icon"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={require('../assets/question_mark.png')} alt="chat icon" />
      {isHovered && (
        <div className="chat-dialog-box">
          <h4>Ask Questions:</h4>
          <ul>
            {sampleQuestions.map((question, index) => (
              <li key={index}>{question}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
        

    </div>

  )
}

export default Chat_section
