import React, {useContext, useState} from 'react'
import {AiOutlineUser,AiOutlineIdcard,} from 'react-icons/ai';
import Cookies from 'js-cookie';
import {RiLockPasswordLine} from 'react-icons/ri';
import {BiSolidKey} from 'react-icons/bi';
import { UserContext } from '../context/UserContext';
import '../styles/__SCADALogin.css'

const Settings = () => {
    
  
  const csrfToken = Cookies.get('csrftoken');
  const [Username,setUsername] = useState('')
  const [Password,setPassword] = useState('')
  const [Clientid,setClientid] = useState('')
  const [Clientkey,setClientkey] = useState('')
  const [filestatus, setfilestatus] = useState<string>('');
  const [fileuploading, setfileuploading] = useState<boolean>(false);
  const {ActiveSiteId, ActiveOrgId} = useContext(UserContext)
  const getUsername = (event:any) =>{
    setUsername(event.target.value)
  }
  const getpassword = (event:any) =>{
    setPassword(event.target.value)
  }
  const getclientid = (event:any) =>{
    setClientid(event.target.value)
  }
  const getclientkey = (event:any) =>{
    setClientkey(event.target.value)
  }


  const getcred = (event:any) => {
    event.preventDefault();
    if (!Username || !Password || !Clientid || !Clientkey) {
        setfilestatus('Note :- All fields are required!!');
      } else {
        setfilestatus('');
    handleupdatescadadata(Username,Password,Clientid,Clientkey)
  }}


  const handleupdatescadadata = async(Username:string,Password:string,Clientid:string,Clientkey:string) => {
    setfileuploading(true);   
    const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
    const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
    const url = 'api/'+ActiveOrgId+'/'+ActiveSiteId+'/fetch_data'
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded',);
    headers.append('X-CSRFToken', csrfToken || '');
    try
      {
        fetch(url,{
            method:'POST',
            headers: headers,
            body: `Username=${Username}&Password=${Password}&Clientid=${Clientid}&Clientkey=${Clientkey}`,
          })
          .then(async response => {
            if (!response.ok) {
              return response.json();
            }
            const data = await response.json();
            
            setfilestatus('File uploaded successfully')
          })
          .then(data => {
            if (data && data.error) {
              
              setfilestatus(data.error);
            }
          })
          .catch(error => {
            
            console.error('There was a problem with the request:', error);
            setfilestatus('Network response was not ok');
          });
          setfileuploading(false);  
      }
          
    catch(error)
      {
        setfilestatus('Network response was not ok');
      }
    }

 return (

    <div className='SCADA_page'>
    <div className='wrapper'>            
        <form action=''>
          <h3>Login</h3>
          <div className="input-box">
            <input type='text' placeholder='Enter Username' onChange={getUsername} value={Username} required/>
            <AiOutlineUser className='SCADA_icon'/>
          </div>
          <div className="input-box">
            <input type='password' placeholder='Enter Password'  onChange={getpassword} value={Password} required />
            <RiLockPasswordLine className='SCADA_icon'/>
          </div>
          <div className="input-box">
            <input type='text' placeholder='Enter Client ID' onChange={getclientid} value={Clientid} required/>
            <AiOutlineIdcard className='SCADA_icon'/>
          </div>
          <div className="input-box">
            <input type='text' placeholder='Enter Client Secret Key' onChange={getclientkey} value={Clientkey} required/>
            <BiSolidKey className='SCADA_icon'/>
          </div>
          <button type="submit" onClick={getcred} disabled={fileuploading}>
              {fileuploading ? 'Connecting...' : 'Connect'}
          </button>
        </form>
        
        {filestatus && (<p className={filestatus === 'File uploaded successfully' ? 'success' : 'error'}>
          {filestatus}
          </p>
        )}
           
    </div>
    </div>

)
}

export default Settings
